
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        extends: BasePage,

        props: {
            type: {
                type: String,
                default: 'generic',
            },
        },

        data () {
            return {
                pageName: 'error',
            };
        },

        computed: {
            isOauthError () {
                return this.type === 'unauthorized';
            },
        },

        i18nOptions: { namespaces: ['error'] },
    };
