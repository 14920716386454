
// This page component is not dynamically imported, which is one
// way of ensuring it's not split out into a separate async chunk.
import ErrorPage from '../views/ErrorPage.vue';
import { DefaultLayout } from '../layouts';

export default [
    {
        path: '/error/:type?',
        alias: '/error/:type',
        component: ErrorPage,
        props: true,
        meta: {
            public: true,
            activeInPhase: ':any',
            activeInMaint: true,
            layout: DefaultLayout,
        },
    },
];
